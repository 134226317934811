import {
    Form, Input, Select, InputNumber, Button, DatePicker, message, Row, Col, AutoComplete, Modal
} from "antd";
import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import moment from "moment";
import { GetProductsLike, GetProducts } from '../../contacts/GetProducts';
const dateFormat = "YYYY/MM/DD HH:mm:ss";

function ItemCard(props) {

    const [isItemModalVisible, setIsItemModalVisible] = useState(false);
    const [deliveryDate, setDeliveryDate] = useState(moment());
    const [productOptions, setProductOptions] = useState([]);
    const [productName, setProductName] = useState("");
    const { Option } = Select;
    const [form] = Form.useForm();

    function onChangeItemDate(date) {
        setDeliveryDate(date)
    }
    async function createItem() {
        const tempData = form.getFieldsValue(true);
        tempData.DeliveryDate = deliveryDate;
        //to show ordermodal items list
        props.setItemsData(itemsData => [...itemsData, { UUID: uuidv4(), ProductNumber: tempData.ProductNumber, ProductName: tempData.ProductName, DeliveryDate: tempData.DeliveryDate, Counts: tempData.Counts, Money: tempData.Money, LotNumber: tempData.LotNumber, OrderItemUUID: props.createData.OrderItemUUID }]);
        props.createData.Items = props.itemsData;
        message.success('新增品項資料成功!');
        setIsItemModalVisible(false);
        form.resetFields();
    }
    //onSearch productNumber to get full data about productName
    async function onSelect(event) {
        await GetProducts(event).then(data => {
            setProductName(data.data[0].name);
            form.setFieldsValue({
                ProductName: data.data[0].name
            })
        })
            .catch(err => console.log(err))

    }
    async function onSearch(event) {
        await GetProductsLike(event).then(data => {
            setProductOptions(data.data);
        })
            .catch(err => console.log(err))
    }
    function showCreateItem() {
        if (isItemModalVisible)
            setIsItemModalVisible(false)
        else
            setIsItemModalVisible(true)
    }

    return (
        <div>
            <Button style={{ position: 'relative', width: '100px' }} onClick={showCreateItem} type="primary">新增品項</Button>

            <Modal
                title="新增品項"
                width={1000}
                visible={isItemModalVisible}
                onOk={showCreateItem}
                onCancel={showCreateItem}
                footer={[
                    <Button key="cancel" onClick={showCreateItem} style={{ position: 'relative', right: '55%' }}>
                        取消
                    </Button>,
                    // <Button type="primary" htmlType="submit" onClick={createItem} style={{ position: 'relative', right: '40%' }}>
                    //     新增品項
                    // </Button>
                ]}>
                <Form
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 10 }}
                    form={form}
                    onFinish={createItem}
                    initialValues={{
                        'productName': { productName }
                    }}
                >
                    <Row gutter={[16, 16]} justify="space-between">
                        <Col span={12}>
                            <Form.Item
                                style={{ width: '600px' }}
                                name="ProductNumber"
                                label="物料編號"
                                rules={[{ required: true, message: '請輸入物料編號' }]}
                            >
                                <AutoComplete
                                    style={{ position: 'relative', left: '20px', width: 200 }}
                                    onSelect={onSelect}
                                    onSearch={onSearch}
                                    placeholder="input here"
                                >
                                    {
                                        productOptions.map((products) => {
                                            return (
                                                <Option key={products.uuid} value={products.number}>{products.number}</Option>
                                            )
                                        })
                                    }
                                </AutoComplete>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                style={{  width: '600px' }}
                                name="ProductName"
                                label="物料名稱"
                                rules={[{ required: true, message: '請輸入物料名稱' }]}
                            >
                                <Input style={{ position: 'relative', left: '20px', width: '200px' }} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                style={{ width: '600px' }}
                                label="交期&emsp;&emsp;"
                                rules={[{ required: true, message: '請輸入交期' }]}
                            >
                                <DatePicker
                                    onChange={onChangeItemDate}
                                    defaultValue={props.createData.DeliveryDate ? moment(props.createData.DeliveryDate, dateFormat) : moment()}
                                    showTime
                                    style={{ position: 'relative', left: '20px', textAlign: 'center' }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                style={{ width: '600px' }}
                                name="LotNumber"
                                label="批號&emsp;&emsp;"
                            >
                                <Input style={{ position: 'relative', left: '20px', width: '100px' }} />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                style={{ width: '600px' }}
                                name="Counts"
                                label="數量&emsp;&emsp;"
                                rules={[{ required: true, message: '請輸入數量' }]}
                            >
                                <InputNumber style={{ position: 'relative', left: '20px', width: '200px' }} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                style={{ width: '600px' }}
                                name="Money"
                                label="金額&emsp;&emsp;"
                                rules={[{ required: true, message: '請輸入金額' }]}
                            >
                                <InputNumber style={{ position: 'relative', left: '20px', width: '200px' }} />
                            </Form.Item>
                        </Col>
                        <Col span={7}>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" style={{ position: 'relative', top: '91px', left: '477px' }}>
                                    新增
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                    {/* </Card> */}
                </Form>
            </Modal>
        </div>
    );

}
export default ItemCard;
