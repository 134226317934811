import { Table, Row, Col, Input, Button, Modal, Form, Select, DatePicker, message, Space, Popconfirm } from 'antd';
import React, { useState } from 'react';
import { getContacts } from '../../contacts/getContacts';
import ItemCard from './ItemCard';
import _ from "lodash";
import moment from "moment";
import { queryOrderNumber, upsertOrder } from '../../contacts/upsertOrderDatas';
import { upsertItem } from '../../contacts/upsertOrderDatas';
import { v4 as uuidv4 } from 'uuid';
import { QueryItemByOrderItemUUID, DeleteItemUUID } from '../../contacts/QueryItemByOrderItemUUID';
import { useHistory } from 'react-router-dom';
const dateFormat = "YYYY/MM/DD HH:mm:ss";

function OrderModal(props) {
    const history = useHistory();
    const columns = [
        {
            title: '物料編號',
            dataIndex: 'ProductNumber',
            key: 'ProductNumber',
        },
        {
            title: '物料名稱',
            dataIndex: 'ProductName',
            key: 'ProductName',
        },
        {
            title: '交期',
            dataIndex: 'DeliveryDate',
            key: 'DeliveryDate',
            render: (record) => (
                <>
                    {moment(record.DeliveryDate).format('YYYY-MM-DD HH:mm:ss')}
                </>
            )
        },
        {
            title: '數量',
            dataIndex: 'Counts',
            key: 'Counts',
        },
        {
            title: '金額',
            dataIndex: 'Money',
            key: 'Money',
        },
        {
            title: '批號',
            dataIndex: 'LotNumber',
            key: 'LotNumber',
        },
        {
            title: '操作',
            key: 'edit',
            render: (text, record) => (
                <Space size="middle">
                    <Popconfirm title="是否要刪除此筆資料?" okText="Yes" cancelText="No" onConfirm={() => handleDelete(record)}>
                        <Button danger>刪除</Button>
                    </Popconfirm>
                </Space>
            )
        },
    ];

    // const { Column } = Table;
    const [form] = Form.useForm();
    const [customersList, setCustomersList] = useState([]);
    const [isItemModalVisible, setIsItemModalVisible] = useState(false);
    const { Option } = Select;
    const [visible, setVisible] = useState(false);
    const [createData, setCreateData] = useState({});
    const [itemsData, setItemsData] = useState([]);
    const [changeDate, setChangeDate] = useState(moment());
    const [customerNumber, setCustomerNumber] = useState("");
    const [customerName, setCustomerName] = useState("");
    const [orderMode, setOrderMode] = useState("未確認訂單");
    const [itemDeleteUUID, setItemDeleteUUID] = useState([]);

    async function handleDelete(data) {
        // record want to delete items UUID
        if (props.record) {
            setItemDeleteUUID(itemDeleteUUID => [...itemDeleteUUID, { UUID: data.UUID }]);
        }

        const tempItemsData = itemsData;
        for (var i = 0; i < tempItemsData.length; i++) {
            if (tempItemsData[i].UUID === data.UUID) {
                tempItemsData.splice(i, 1);
                break;
            }
        }
        await setItemsData([]);
        await setItemsData(tempItemsData);

        message.success('刪除成功!');
    };

    function selectChangeCustomer(value) {
        const tempCustomer = value.split(', ');
        setCustomerNumber(tempCustomer[0]);
        setCustomerName(tempCustomer[1]);
    }


    async function queryCustomersList() {
        await getContacts("", "").then(data => {
            //filter customerMode is  true data
            let trueModeData = _.filter(data, ['CustomerMode', "true"]);
            setCustomersList(trueModeData);
        })
            .catch(err => console.log(err))
    }

    async function handleCancel() {
        setVisible(false);
        form.resetFields();
        setItemsData([]);
        setCreateData({});
        setItemDeleteUUID([]);
    }
    async function edit() {
        // defaultValue = { props.record.CustomerNumber }
        setCustomerNumber(props.record.CustomerNumber)
        setCustomerName(props.record.CustomerName)
        await QueryItemByOrderItemUUID(props.record.OrderItemUUID).then(
            data => {
                data.map((items) => {
                    setItemsData(itemsData => [...itemsData, items]);
                    return data
                }
                )
            }
        ).catch(err => console.log(err));
        queryCustomersList();
        setCreateData(props.record);
        //get DeliveryDate if onchange not change
        if (props.record.DeliveryDate) {
            setChangeDate(props.record.DeliveryDate);
            setOrderMode(props.record.OrderMode);
        }
        //load order data to modal form
        await form.setFieldsValue(props.record);
        setVisible(true);

    }
    function createOrder() {
        createData.UUID = uuidv4();
        createData.OrderItemUUID = uuidv4();
        queryCustomersList();
        setVisible(true);
    }
    //存檔
    async function saveOrderDataChange() {
        if (itemsData.length === 0) {
            message.error('品項尚未新增，不可為空白!');
            return
        }
        const tempData = form.getFieldsValue(true);
        //select option value
        tempData.Priority = parseInt(tempData.Priority);
        tempData.OrderMode = orderMode;
        tempData.CustomerName = customerName;
        tempData.CustomerNumber = customerNumber;
        //merge createData and tempData objects
        setCreateData(Object.assign(createData, tempData));
        createData.DeliveryDate = changeDate;

        //check orderNumber repeat ornot
        if (!props.record) {
            var tempOrderNumberData = await queryOrderNumber(createData.OrderNumber);
            if (tempOrderNumberData.length !== 0) {
                message.error('訂單編號已重複，請重新輸入!');
                return;
            }
        }


        setVisible(false);
        //summary items counts and money then save to DB
        if (itemsData.length > 0) {
            createData.Counts = _.sumBy(itemsData, 'Counts')
            createData.Money = _.sumBy(itemsData, 'Money')
        }
        await upsertOrder(createData);
        if (itemsData != null) {
            await upsertItem(itemsData);
        }
        message.info('儲存變更成功!');

        //delete item
        await DeleteItemUUID(itemDeleteUUID);

        //empty change
        form.resetFields();
        setItemsData([]);
        setCreateData({});
        setCustomerName("");
        setCustomerNumber("");

        //refresh search from
        await props.searchByRule();
        await history.push("/orders/");
        await history.push("/orders/all");
    }

    function onChangeDate(date) {
        setChangeDate(date);
    }

    function selectChangeOrderMode(data) {
        setOrderMode(data);
    }
    return (
        <div>
            {props.record ?
                <Button type="primary" onClick={edit}>編輯</Button>
                :
                <Button type="primary" onClick={createOrder}>創建新訂單</Button>
            }

            <Modal
                title="新增訂單資料"
                visible={visible}
                width={1000}
                onCancel={handleCancel}
                footer={
                    [
                        <Button key="cancel" onClick={handleCancel} style={{ position: 'relative', right: '43%' }}>
                            取消
                        </Button>,
                        <Button key="submit" type="primary" onClick={saveOrderDataChange} style={{ position: 'relative', right: '40%' }}>
                            儲存訂單
                        </Button>
                    ]}
            >
                <Form
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 10 }}
                    layout="horizontal"
                    style={{ width: "100%" }}
                    form={form}
                    // onFieldsChange={changeOrderDatas}
                    onFinish={saveOrderDataChange}
                    initialValues={{
                        'OrderNumber': moment().format('YYYYMMDD-HHmmss'),
                        'Priority': 10
                    }}
                >
                    <Row gutter={[16, 16]}>
                        <Col span={12} >
                            <Form.Item
                                style={{ width: '600px' }}
                                name="OrderNumber"
                                label="訂單編號"
                                rules={[{ required: true, message: '請輸入訂單編號!' }]}
                            >
                                <Input style={{ position: 'relative', left: '20px', width: '200px' }} allowClear />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                style={{ width: '600px' }}
                                // name="CustomerName"
                                label="客戶&emsp;&emsp;&emsp;&emsp;"
                                rules={[{ required: true, message: '請選擇客戶!' }]}
                            >
                                <Select onChange={selectChangeCustomer} defaultValue={customerNumber + " " + customerName} style={{ position: 'relative', left: '20px', width: '200px' }}
                                >
                                    {
                                        customersList.map((item) => {
                                            return (
                                                <Option key={item.UUID} value={item.CustomerNumber + ", " + item.CustomerName}>{item.CustomerNumber + ", " + item.CustomerName}</Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                style={{ width: '600px' }}
                                name="CustomizedSerialNumber"
                                label="自訂編號"
                            >
                                <Input style={{ position: 'relative', left: '20px', width: '200px' }} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                style={{ width: '600px' }}
                                name="CustomerOrderNumber"
                                label="客戶訂單編號"
                            >
                                <Input style={{ position: 'relative', left: '20px', width: '200px' }} />
                            </Form.Item>
                        </Col>
                        <Col span={12} >
                            <Form.Item
                                style={{ width: '600px' }}
                                // name="DeliveryDate"
                                label="交期&emsp;&emsp;"
                                rules={[{ required: true, message: '請輸入交期!' }]}
                            >
                                <DatePicker
                                    // default-value="moment('01/01/2015', dateFormatList[0])"
                                    defaultValue={createData.DeliveryDate ? moment(createData.DeliveryDate, dateFormat) : moment()}
                                    // format={dateFormat}
                                    onChange={onChangeDate}
                                    // defaultValue={moment()}
                                    showTime
                                    style={{ position: 'relative', left: '20px', textAlign: 'center' }}
                                />

                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                style={{ width: '600px' }}
                                name="OrderMode"
                                label="訂單狀態&emsp;&emsp;"
                            // rules={[{ required: true, message: '請輸入訂單狀態!' }]}
                            >
                                <Select onChange={selectChangeOrderMode} defaultValue="未確認訂單" style={{ position: 'relative', left: '20px', width: '200px' }} >
                                    <Option value="未確認訂單">
                                        未確認訂單
                                    </Option>
                                    <Option value="尋單">
                                        尋單
                                    </Option>
                                    <Option value="確認訂單">
                                        確認訂單
                                    </Option>
                                    <Option value="已排程">
                                        已排程
                                    </Option>
                                    <Option value="生產中">
                                        生產中
                                    </Option>
                                    <Option value="已交付">
                                        已交付
                                    </Option>
                                    <Option value="已關閉">
                                        已關閉
                                    </Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                style={{ width: '600px' }}
                                name="Priority"
                                label="優先度&emsp;"
                                rules={[{ required: true, message: '請輸入優先度!' }]}
                            >
                                {/* <InputNumber min={1} max={10} style={{ position: 'relative', left: '20px', width: '200px' }} /> */}
                                <Select style={{ position: 'relative', left: '20px', width: '200px' }} showSearch>
                                    <Option value="1">
                                        1
                                    </Option>
                                    <Option value="2">
                                        2
                                    </Option>
                                    <Option value="3">
                                        3
                                    </Option>
                                    <Option value="4">
                                        4
                                    </Option>
                                    <Option value="5">
                                        5
                                    </Option>
                                    <Option value="6">
                                        6
                                    </Option>
                                    <Option value="7">
                                        7
                                    </Option>
                                    <Option value="8">
                                        8
                                    </Option>
                                    <Option value="9">
                                        9
                                    </Option>
                                    <Option value="10">
                                        10
                                    </Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                style={{ width: '600px' }}
                                name="ShipMethod"
                                label="貨運方式&emsp;&emsp;"
                            >
                                <Input style={{ position: 'relative', left: '20px', width: '200px' }} />
                            </Form.Item>

                        </Col>

                        <div style={{ width: '1000vh', position: 'relative', top: '5%' }}>
                            <ItemCard visible={isItemModalVisible} setIsItemModalVisible={setIsItemModalVisible} createData={createData} itemsData={itemsData} setItemsData={setItemsData} style={{ position: 'relative', top: '10px' }}></ItemCard>
                            <Table columns={columns} dataSource={itemsData} style={{ position: 'relative', top: '5%' }}>
                            </Table>
                        </div>

                    </Row>
                    <Row gutter={[16, 16]} style={{ position: 'relative', top: '30px' }}>
                        <Col span={12}>
                            <Form.Item
                                style={{ width: '450px' }}
                                name="Counts"
                                label="總數量"
                            >
                                {_.sumBy(itemsData, 'Counts')}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                style={{ width: '450px' }}
                                name="Money"
                                label="總金額"
                            >
                                {_.sumBy(itemsData, 'Money')}
                            </Form.Item>
                        </Col>
                        {/* <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                            <Button type="primary" htmlType="submit">
                                存檔
                            </Button>
                        </Form.Item> */}
                        <Form.Item>
                        </Form.Item>
                    </Row>
                </Form>
            </Modal>
        </div>

    )
}
export default OrderModal;
