import axiosInstance from "../api/axios";
export const OrderDelete = (props) =>
{
    axiosInstance.delete(`/order/delete?UUID=${props}`)
        .then((res) => {
            console.log(res.data)
        })
        .catch((err) => console.log("err",err));
}
export default OrderDelete;
