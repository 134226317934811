import axiosInstance from "../api/axios";
export const CustomerDelete = (props) =>
{
    axiosInstance.delete(`/customer/delete?UUID=${props}`)
        .then((res) => {
            console.log(res.data)
        })
        .catch((err) => console.log("err",err));
}
export default CustomerDelete;
