import './App.css';
import Layout from "./layout/layout";
import { BrowserRouter as Router} from 'react-router-dom';
import Cookies from 'js-cookie';
import axios from 'axios';
import queryString from 'query-string';
import {
  initAxiosInstance,
  getAppPermission,
} from './api/apiService';

function App() {
  const token = Cookies.get('token');
  const user = Cookies.get('user');
  if (process.env.REACT_APP_ENV === 'dev') {
    const t = 'eyJhbGciOiJSUzI1NiIsImtpZCI6IjM2NGU4NTQ1NzI5OWQ5NzIxYjczNDQyZGNiNTQ3Y2U2ZDk4NGRmNTkiLCJ0eXAiOiJKV1QifQ.eyJuYW1lIjoiRFogQWRtaW4iLCJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vYWxlcnQtaGVpZ2h0LTI1NzMwNCIsImF1ZCI6ImFsZXJ0LWhlaWdodC0yNTczMDQiLCJhdXRoX3RpbWUiOjE2Mjk0NDA4NzUsInVzZXJfaWQiOiJiT2hlQlY2aDFQTjRTbG9wc2g0N1RneFZtWHYxIiwic3ViIjoiYk9oZUJWNmgxUE40U2xvcHNoNDdUZ3hWbVh2MSIsImlhdCI6MTYyOTQ0MDg3NSwiZXhwIjoxNjI5NDQ0NDc1LCJlbWFpbCI6ImRldi5pb0Bkb3R6ZXJvLnRlY2giLCJlbWFpbF92ZXJpZmllZCI6ZmFsc2UsImZpcmViYXNlIjp7ImlkZW50aXRpZXMiOnsiZW1haWwiOlsiZGV2LmlvQGRvdHplcm8udGVjaCJdfSwic2lnbl9pbl9wcm92aWRlciI6InBhc3N3b3JkIiwidGVuYW50IjoiZXh0ZW5kLWZvcm1pbmctaWlucW0ifX0.R5PnvIJgcpkBLeY1gLaRdAL4E2f0xMz17ZJ51GumVODSY1I-Zl-UbsAcWR26zrpukQgsdIj1_q4URlTJDNA15tJN-BuRxhsvmTcz_cXgBrxHa2CiKNpj4JSVTozL-iNDScgoRNZlrMYyvY3w0QiQtJF23pHk1HB4up7I50tTic4TbJLV3y1FcqZHm7Bt7BsPUbEDtEtdBIHVJqCknhVVvKOPUTVwe5ruOi4BSzXVetgfk5pu3mpFrpnpjGm6yRq-i00iik80TeeB3Zm3FEex1bFh_Bln98zTCj6xw6mQ0wpCF6HefVdzVyyGvdGcfsRC6uGjL-Bgg1FOlLWIa6kwZA';
    axios.defaults.headers.common['Authorization'] = `Bearer ${t}`;
    sessionStorage.setItem('tenant_id', 'group1-t0yq0');
    initAxiosInstance();
    // 2020-11-27
  } else {
    if (token !== undefined && user !== undefined) /* 有 login */ {
      const userObj = JSON.parse(user);
      // eslint-disable-next-line max-len
      const ssoUrl = process.env.REACT_APP_LOGIN_URL + '/login?tenantId=' + userObj.tenantId + '&redirectUri=' + window.location.origin;
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      sessionStorage.setItem('tenant_id', userObj.tenantId);
      sessionStorage.setItem('sso_url', ssoUrl);
      initAxiosInstance();
      getAppPermission(token).then((res) => {
        /*
        **  2020-11-17 newPermission
        *   {
        *     access: true,
        *     permission: {
        *                  admin: true,
        *                  worker: true,
        *                 }
        *    }
        ** */
        if (!res.access) {
          alert('你沒有進入 SD 頁面的權限！[access]');
          const loginUrl = process.env.REACT_APP_LOGIN_URL + '/login?tenantId=' + userObj.tenantId;
          window.location.replace(loginUrl);
        }
      }).catch((err) => {
        alert('你沒有進入 SD 頁面的權限！');
        const loginUrl = process.env.REACT_APP_LOGIN_URL + '/login?tenantId=' + userObj.tenantId;
        window.location.replace(loginUrl);
      });
    } else /* 沒 login */ {
      // 轉傳到 login 頁面
      const params = queryString.parse(window.location.search);
      const redirectUrl = window.location.origin;
      const tenantId = sessionStorage.getItem('tenant_id') || params.tenantId || '';
      const loginUrl = process.env.REACT_APP_LOGIN_URL + '/login?tenantId=' + tenantId + '&redirectUri=' + redirectUrl;
      window.location.replace(loginUrl);
      return null;
    }
  }

  return (
    <Router>
        <Layout/>
    </Router>
  );
}

export default App;
