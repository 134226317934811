import {
    Table, Space, Popconfirm, message, Row,
    Col, Input, Button, Tag
} from 'antd';
import React, { useState, useEffect } from 'react';
// import { useHistory } from 'react-router-dom';
import CustomerDelete from '../../contacts/CustomerDelete';
import { getContacts} from '../../contacts/getContacts';
// import upsertCustomerData from '../../contacts/upsertCustomerData';
import CustomerModal from "./CustomerModal";
const Customer = (props) => {
    const columns = [
        {
            title: '客戶編號',
            dataIndex: 'CustomerNumber',
            key: 'CustomerNumber',
        },
        {
            title: '客戶名稱',
            dataIndex: 'CustomerName',
            key: 'CustomerName',
        },
        {
            title: '客戶地址',
            dataIndex: 'DestinationAddress',
            key: 'DestinationAddress'
        },
        {
            title: '貨運地點',
            dataIndex: 'SourceAddress',
            key: 'SourceAddress'
        },
        {
            title: '城市',
            dataIndex: 'City',
            key: 'City'
        },
        {
            title: '狀態',
            dataIndex: 'CustomerMode',
            key: 'CustomerMode',
            render: (CustomerMode) => (
                <>
                    <Tag color='blue' key={CustomerMode}>
                        {
                            CustomerMode==="true"?
                            "啟用":"停用"
                        }
                    </Tag>
                </>
            )
        },
        {
            title: '管理',
            key: 'edit',
            render: (text, record) => (
                <Space size="middle">
                    {/* <Button type="primary" onClick={() => edit(record)}>編輯</Button> */}
                    <CustomerModal record={record} callQueryCustomerAPI={searchByRule}></CustomerModal>
                    <Popconfirm title="是否要刪除此筆資料?" okText="Yes" cancelText="No" onConfirm={() => handleDelete(record)}>
                        <Button danger>刪除</Button>
                    </Popconfirm>
                </Space>)
        },
    ];

    // const history = useHistory();
    // const { Column } = Table;

    // const [form] = Form.useForm();
    // const [visible, setVisible] = useState(false);
    // const [loading,setLoading] = useState(false);
    const [customerData, setCustomerData] = useState();
    const [customerName, setCustomerName] = useState("");
    const [customerNumber, setCustomerNumber] = useState("");

    async function handleDelete(data) {
        console.log("data", data.UUID);
        await CustomerDelete(data.UUID);
        setTimeout(() => {
            searchByRule();//reload query customer data
        }, 1500);
        message.success('刪除成功!');
    };

    // function edit(data) {
    //     history.push(`/customers/${data.UUID}`);
    //     props.setCustomerData(data)
    //     console.log(data)
    // };

    // async function onFinish(event) {
    //     await upsertCustomerData(event);
    //     // setLoading(true);
    //     setTimeout(() => {
    //         // setVisible(false);
    //         callQueryCustomerAPI();//reload query customer data
    //     }, 1500);
    //     message.success('新增客戶資料成功!');
    // }


    async function onChangeCustomerName(e) {
        setCustomerName(e.target.value)
    }

    async function onChangeCustomerNumber(e) {
        setCustomerNumber(e.target.value)
    }

    async function searchByRule() {
        setCustomerData("");
        await getContacts(customerName, customerNumber).then(data => {
            setCustomerData(data);
            console.log(data)
        })
            .catch(err => console.log(err))
    }

    useEffect(() => {
        searchByRule();
        // eslint-disable-next-line
    }, [])

    return (
        // <CustomersContext.Provider value={{customerData}}>
        <>
            <Row gutter={[8, 0]}>
                <Col span={24}>
                    <Row gutter={[8, 0]}>
                    </Row>
                    <Row gutter={[16, 16]} >
                        <Col xs={24} sm={24}>
                            <CustomerModal callQueryCustomerAPI={searchByRule}></CustomerModal>
                        </Col>
                        <Col span={2} >
                            <h1>客戶名稱 : </h1>
                        </Col>
                        <Col span={4} >
                            <Input onChange={onChangeCustomerName} placeholder="客戶名稱" size="middle" style={{ width: '100%', textAlign: 'center', right: '5vh' }} />
                        </Col>
                        <Col span={2} >
                            <h1>客戶編號 : </h1>
                        </Col>
                        <Col span={4} >
                            <Input onChange={onChangeCustomerNumber} placeholder="客戶編號" size="middle" style={{ width: '100%', textAlign: 'center', right: '5vh' }} />
                        </Col>
                        <Col span={3} >
                            <Button size="middle" type="primary" onClick={searchByRule}>搜尋</Button>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Table columns={columns} dataSource={customerData} style={{ position: 'relative', top: '5%' }} pagination={{ pageSize: 8 }}>
            </Table>
        </>
    )
}
export default Customer;
