import { Table, Space, Popconfirm, message, Button } from 'antd';
import React, { useState, useEffect } from 'react';
import OrderDelete from '../../contacts/OrderDelete';
import OrderModal from './OrderModal';
import _ from "lodash";
import { QueryItemByOrderItemUUID } from '../../contacts/QueryItemByOrderItemUUID';
import moment from "moment";
function OrdersTable(props) {
    const [orderData, setOrderData] = useState();
    const [itemsData, setItemsData] = useState([]);

    const expandedRowRender = (record) => {
        const columns = [
            {
                title: '物料編號',
                dataIndex: 'ProductNumber',
                key: 'ProductNumber',
            },
            {
                title: '物料名稱',
                dataIndex: 'ProductName',
                key: 'ProductName',
            },
            {
                title: '交期',
                dataIndex: 'DeliveryDate',
                key: 'DeliveryDate',
                render: (record) => (
                    <>
                        {moment(record.DeliveryDate).format('YYYY-MM-DD HH:mm:ss')}
                    </>
                )
            },
            {
                title: '數量',
                dataIndex: 'Counts',
                key: 'Counts',
            },
            {
                title: '金額',
                dataIndex: 'Money',
                key: 'Money',
            },
            {
                title: '批號',
                dataIndex: 'LotNumber',
                key: 'LotNumber',
            },
        ];
        return <Table columns={columns} dataSource={record.id} pagination={false} />;
    };

    const columns = [
        {
            title: '創建日期',
            key: 'createTime',
            sorter: (a, b) => {
                let aTimeString = a.createTime;
                let bTimeString = b.createTime;
                let aTime = new Date(aTimeString).getTime();
                let bTime = new Date(bTimeString).getTime();
                return aTime - bTime;
            },
            render: (record) => (
                <>
                    {moment(record.createTime).format('YYYY-MM-DD HH:mm:ss')}
                </>
            )
        },
        {
            title: '更新日期',
            key: 'updateTime',
            sorter: (a, b) => {
                let aTimeString = a.updateTime;
                let bTimeString = b.updateTime;
                let aTime = new Date(aTimeString).getTime();
                let bTime = new Date(bTimeString).getTime();
                return aTime - bTime;
            },
            render: (record) => (
                <>
                    {moment(record.updateTime).format('YYYY-MM-DD HH:mm:ss')}
                </>
            )
        },
        {
            title: '訂單編號',
            sort: true,
            dataIndex: 'OrderNumber',
            key: 'OrderNumber'
        },
        {
            title: '客戶編號',
            dataIndex: 'CustomerNumber',
            key: 'CustomerNumber'
        },
        {
            title: '客戶名稱',
            dataIndex: 'CustomerName',
            key: 'CustomerName'
        },
        {
            title: '自訂編號',
            dataIndex: 'CustomizedSerialNumber',
            key: 'CustomizedSerialNumber'
        },
        {
            title: '數量',
            dataIndex: 'Counts',
            key: 'Counts'
        },
        {
            title: '金額',
            dataIndex: 'Money',
            key: 'Money'
        },
        {
            title: '客戶訂單編號',
            dataIndex: 'CustomerOrderNumber',
            key: 'CustomerOrderNumber'
        },
        {
            title: '訂單狀態',
            dataIndex: 'OrderMode',
            key: 'OrderMode'
        },
        {
            title: '交期',
            key: 'DeliveryDate',
            sorter: (a, b) => {
                let aTimeString = a.DeliveryDate;
                let bTimeString = b.DeliveryDate;
                let aTime = new Date(aTimeString).getTime();
                let bTime = new Date(bTimeString).getTime();
                return aTime - bTime;
            },
            render: (record) => (
                <>
                    {moment(record.DeliveryDate).format('YYYY-MM-DD HH:mm:ss')}
                </>
            )
        },
        {
            title: '優先度',
            dataIndex: 'Priority',
            key: 'Priority'
        },
        {
            title: '出貨方式',
            dataIndex: 'ShipMethod',
            key: 'ShipMethod'
        },
        {
            title: '管理',
            key: 'edit',
            render: (text, record) => (
                <Space size="middle">
                    <OrderModal record={record} searchByRule={props.searchByRule} setItemsData={setItemsData} itemsData={itemsData} setCreateData={props.setCreateData} createData={props.createData}></OrderModal>
                    <Popconfirm title="是否要刪除此筆資料?" okText="Yes" cancelText="No" onConfirm={() => handleDelete(record)}>
                        <Button danger>刪除</Button>
                    </Popconfirm>
                </Space>
            )
        },
    ];

    async function handleDelete(data) {
        await OrderDelete(data.UUID);
        message.success('刪除成功!');
        setTimeout(() => {
            props.searchByRule();
        }, 1500);
    };
    async function onTableRowExpand(expanded, record) {
        if (expanded) {
            setItemsData([]);
            QueryItemByOrderItemUUID(record.OrderItemUUID).then(
                data => {
                    for (let i = 0; i < data.length; ++i) {
                        data[i].key = i;
                    }
                    record.id = data;
                    // data.key = data.UUID;
                    data.map((items) => {
                        setItemsData(itemsData => [...itemsData, items]);
                        return data
                    }
                    )
                }
            ).catch(err => console.log(err));

        } else {
            console.log("setItemsData", itemsData)
            // this.setState({ expandedRowKeys: [] });
        }
    };
    //kind of order mode
    function OrderModeDiff() {
        if (props.orderMode !== "") {
            let diffModeData = _.filter(props.orderDataByDate, ['OrderMode', props.orderMode]);
            setOrderData(diffModeData);
        } else {
            setOrderData(props.orderDataByDate);
        }
        console.log("orderData",orderData)
    }

    useEffect(() => {
        OrderModeDiff();
        //eslint-disable-next-line
    }, [])

    return (
        <>
            <Table
                rowKey={ record => record.key}
                style={{ position: 'relative', top: '5%' }}
                pagination={{ pageSize: 8 }}
                columns={columns}
                expandable={{ expandedRowRender }}
                dataSource={orderData}
                onExpand={(expanded, record) => onTableRowExpand(expanded, record)}
            />
        </>
    )
}
export default OrdersTable;
