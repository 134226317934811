import React from 'react';
import { Pie } from '@ant-design/charts';
const CustomersPie = ({data}) => {
    const pieConfig = {
        forceFit: true,
        radius: 0.8,
        data,
        angleField: 'value',
        colorField: 'type',
        label: {
            visible: true,
            type: 'inner',
        },
    };

    return (
        <Pie {...pieConfig}></Pie>

    )
}
export default CustomersPie;
