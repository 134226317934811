import axiosInstance from "../api/axios";
export const QueryItemByOrderItemUUID = (OrderItemUUID) => {
    const promise = axiosInstance.get(`/item/queryItems?OrderItemUUID=${OrderItemUUID}`);
    const dataPromise = promise.then((response) => response.data)
    return dataPromise
}

export const DeleteItemUUID = (UUID) => {
    UUID.forEach((items) => {
        const promise = axiosInstance.delete(`/item/delete?UUID=${items.UUID}`);
        const dataPromise = promise.then((response) => response.data)
        return dataPromise
    })
}

export const searchProductNumber = (productNumber) => {
    const promise = axiosInstance.get(`/item/productNumber?productNumber=${productNumber}`);
    const dataPromise = promise.then((response) => response.data)
    return dataPromise
}

export default QueryItemByOrderItemUUID;
