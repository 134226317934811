import axios from "axios";
import queryString from 'query-string';
import Cookies from 'js-cookie';

export const BASE_URL = process.env.REACT_APP_API_BASE_URL
// console.log("baseURL",BASE_URL)
let headers = {};

if (localStorage.token) {
    headers.Authorization = `Bearer ${Cookies.get('token')}`;
}

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers,
});

axiosInstance.interceptors.request.use((request) => {
  request.headers['Authorization'] = `Bearer ${Cookies.get('token')}`;
  return request;
}, (error) => {
  return Promise.reject(error);
});

axiosInstance.interceptors.response.use(
  (response) =>
    new Promise((resolve, reject) => {
      resolve(response);
    }),
  (error) => {
    if (!error.response) {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }

    if (error.response.status.toString() === '401') {
      // redirect 到 login 頁面！
      const params = queryString.parse(window.location.search);
      const redirectUrl = window.location.origin;
      const tenantId = sessionStorage.getItem('tenant_id') || params.tenantId || '';
      const loginUrl = process.env.REACT_APP_LOGIN_URL + '/login?tenantId=' + tenantId + '&redirectUri=' + redirectUrl;
      //
      if (process.env.REACT_APP_ENV !== 'dev') {
        window.location.replace(loginUrl);
      }
    }

    if (error.response.status === 403) {
      localStorage.removeItem("token");

      // if (history) {
      //   history.push("/auth/login");
      // } else {
      //   window.location = "/auth/login";
      // }
    } else {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }
  });



export const getAppPermission = async (token) => {
  return axios({
    method: 'get',
    url: process.env.REACT_APP_USER_URL + 'permission/app?name=sd',
    headers: { Authorization: `Bearer ${token}` },
  }).then((res) => {
    return res.data;
  });
};
export default axiosInstance;
