import axiosInstance from "../api/axios";
export const GetProductsLike =  (prodcutNumber) => {
    //prodcutNumber
    const promise = axiosInstance.get(`https://dotzerotech-work-order-api.dotzero.app/v1/products/?page=like&number=${prodcutNumber}`);
    const dataPromise = promise.then((response) => response.data)
    return dataPromise
}
export const GetProducts =  (prodcutNumber) => {
    //prodcutNumber
    const promise = axiosInstance.get(`https://dotzerotech-work-order-api.dotzero.app/v1/products/?page=like&number=${prodcutNumber}`);
    const dataPromise = promise.then((response) => response.data)
    return dataPromise
}
export default GetProducts;
