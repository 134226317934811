import React, { useState } from 'react';
import {
  Layout,
  Row,
  Col,
  Menu,
  Popover,
  Modal,
  Breadcrumb,
  Button
} from 'antd';
import {
  LogoutOutlined,
} from '@ant-design/icons';

import './layout.css';
import Customer from "../components/customer/Customer";
import Order from '../components/order/Order';
import { Route, NavLink, Switch } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import SD from '../assets/img/SD.png';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  InsertRowRightOutlined,
  AppstoreOutlined
} from '@ant-design/icons';
import Home from '../components/home/Home';
import { DZRelease } from '@dotzerotech/release-frontend';
import './AppSwitcher.css';


const { Header, Sider, Content, Footer } = Layout;
const { confirm } = Modal;



function LayoutSide() {
  const history = useHistory();
  const [switcherZIndex, setSwitcherZIndex] = useState(-1);
  const [collapsed, setCollapsed] = useState(true);
  const [breadcrumbTitle, setBreadcrumbTitle] = useState('home');
  function changeTitle(data) {
    setBreadcrumbTitle(data.key)
  }
  function changeHomeTitle(data) {
    setBreadcrumbTitle(data)
    history.push('/')
  }

  function toggle() {
    setCollapsed(!collapsed);
  };

  const onSignOut = () => {
    const tenantId = sessionStorage.getItem('tenant_id') || '';
    const logoutUrl = process.env.REACT_APP_LOGIN_URL +
      '/logout?tenantId=' + tenantId + '&redirectUri=' + window.location.origin;
    sessionStorage.clear();
    localStorage.clear();
    window.location.replace(logoutUrl);
  };


  const showConfirm = () => {
    confirm({
      title: '確定登出？',
      icon: <LogoutOutlined />,
      okText: '確認',
      cancelText: '取消',
      onOk() {
        onSignOut();
      },
      onCancel() {
      },
    });
  };

  return (
    <Layout
      style={{ overflow: 'hidden', height: '100vh', left: 0, }}
      onClick={ () => {
        if( switcherZIndex !== -1 ) {
          setSwitcherZIndex(-1);
        }
      }}
    >
      <Sider
        trigger={null} collapsible collapsed={collapsed}
        theme="dark"
        breakpoint="lg"
        collapsedWidth={window.innerWidth > 1100 ? 80 : 0}
      // style={{ overflow: 'auto', height: '100vh', left: 0, }}
      >
        <span style={{ cursor: 'pointer' }} onClick={() => changeHomeTitle("home")}>
          {
            collapsed ?
              <Row style={{ marginTop: '10px', textAlign: 'center', display: collapsed ? '' : 'none' }} >
                <Col span={24}>
                  <div>
                    <img width="44" height="44" alt="" src={SD} />
                  </div>
                </Col>
              </Row> :
              <Row gutter={[0, 0]} style={{ marginTop: '10px', display: collapsed ? 'none' : '' }}>
                <Col span={9}>
                  <div style={{ textAlign: 'center' }}>
                    <img width="40" height="40" alt="" src={SD} />
                  </div>
                </Col>
                <Col span={15}
                  style={{
                    color: 'white',
                    width: 'calc(100% - 20px)',
                    fontWeight: 'normal',
                  }}>
                  <div style={{ textAlign: 'left', fontSize: '16px' }}>訂單系統</div>
                  <div style={{ textAlign: 'left', fontSize: '10px' }}>Sales Distribution</div>
                </Col>
              </Row>
          }
        </span>
        <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', height: '92vh' }}>
          <Menu theme="dark" mode="inline" defaultSelectedKeys={['1']}>
            <Menu.Item key="customer" icon={<UserOutlined />} onClick={changeTitle}>
              <NavLink to="/customers" >
                客戶資料
              </NavLink>
            </Menu.Item>
            <Menu.Item key="order" icon={<InsertRowRightOutlined />} onClick={changeTitle} >
              <NavLink to="/orders">
                訂單資料
              </NavLink>
            </Menu.Item>
          </Menu>
          {/* ================= logout =================== */}
          <Menu
            mode="inline"
            theme="dark"
            defaultSelectedKeys={['/']}
            selectedKeys={[window.location.pathname]}
            style={{ marginTop: '75vh' }}
          >
            <Menu.Item key="/logout" onClick={showConfirm}>
              <LogoutOutlined />
              <span> 登出</span>
            </Menu.Item>
          </Menu>
        </div>
      </Sider>
      <Layout className="site-layout">
        <Header className="site-layout-background"
          style={{
            backgroundColor: '#fff',
            padding: 0,
            position: 'fixed',
            zIndex: 999,
            width: '100%',
            borderBottom: 'solid 1px #F0F2F4',
          }}>
          <Row>
            <Col>
              {
                collapsed ?
                  <MenuUnfoldOutlined className='trigger' onClick={toggle}></MenuUnfoldOutlined> :
                  <MenuFoldOutlined className='trigger' onClick={toggle}></MenuFoldOutlined>
              }
            </Col>
            <Col>
              <Breadcrumb className='trigger'>
                <Breadcrumb.Item><a href={process.env.REACT_APP_SD_URL}>首頁</a></Breadcrumb.Item>
                {
                  breadcrumbTitle === "home" ?
                    <>
                      {/* <a href={process.env.REACT_APP_SD_URL}>首頁</a> */}
                    </> :
                    breadcrumbTitle === "customer" ?
                      <Breadcrumb.Item>
                        <a href={process.env.REACT_APP_SD_CUSTOMER_URL}>客戶</a>
                      </Breadcrumb.Item> :
                      <Breadcrumb.Item>
                        <a href={process.env.REACT_APP_SD_ORDER_URL}>訂單</a>
                      </Breadcrumb.Item>

                }
              </Breadcrumb>
            </Col>
            <div style={{ position: 'fixed', top: 0, right: 50, zIndex: 10, padding: '5' }}>
              <Row gutter={[8, 8]}>
                <Col>
                  <DZRelease projects={[{ projectID: '143', projectName: 'SD' }]} />
                </Col>
                <Col span={4} >
                  <div className="layout-header-app-switcher">
                    <Popover
                      title={ null }
                      placement="bottom"
                      visible={ true }
                      zIndex={ switcherZIndex }
                      overlayClassName="app-switcher-popover"
                      overlayInnerStyle={{ borderRadius: "15px" }}
                      content={
                        <div className='mask'>
                          <iframe
                            src={ process.env.REACT_APP_SWITCHER_URL }
                            title="app-switcher"
                            frameBorder="0"
                            className="app-switcher"
                          />
                        </div>
                      }
                    >
                      <Button
                        className="layout-header-app-switcher-btn"
                        type="text"
                        shape="circle"
                        icon={<AppstoreOutlined color="black" />}
                        onClick={() => {
                          setSwitcherZIndex(switcherZIndex === -1 ? 999 : -1);
                        }}
                      />
                    </Popover>
                  </div>
                </Col>
              </Row>
            </div>

          </Row>
          {/* {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
            className: 'trigger',
            onClick: toggle,
          })} */}
        </Header>
        <Content
          className="site-layout-background"
          style={{
            height: "100%",
            margin: '70px 16px',
            padding: 24,
            minHeight: 280,
            overflow: 'auto',

          }}
        >
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="/customers">
              <Customer />
            </Route>
            <Route path="/orders">
              <Order />
            </Route>
          </Switch>
        </Content>
        <Footer
          style={{
            margin: '-50px 0px 0px 0px',
            backgroundColor: '#fff',
            textAlign: 'center',
          }}
        >
          DotZero v1.0.0 ©{new Date().getFullYear()}
        </Footer>

      </Layout>
    </Layout>
  );

}
export default LayoutSide;
