import React, { useState, useEffect } from 'react';
import { Tabs, Row, Col, Input, Button, DatePicker, Select, AutoComplete } from 'antd';
import { getContacts } from '../../contacts/getContacts';
import { OrderQueryByDate } from "../../contacts/OrderQuery";
import { GetProductsLike } from '../../contacts/GetProducts';
import OrdersTable from './OrdersTable';
import { Route, Switch, useHistory } from 'react-router-dom';
import OrderModal from './OrderModal';
import moment from "moment";
import _ from "lodash";
import { searchProductNumber } from '../../contacts/QueryItemByOrderItemUUID';
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

function Order() {
    const [customersList, setCustomersList] = useState([]);
    const [orderDataByDate, setOrderDataByDate] = useState("");
    const dateFormat = 'YYYY-MM-DD HH:mm:ss';
    const [startEndDate, setStartEndDate] = useState([moment('2021/10/01 00:00:00').format(dateFormat), moment().format(dateFormat)]);
    const [orderNumber, setOrderNumber] = useState("");
    const [customerName, setCustomerName] = useState("");
    const [orderItemUUID, setOrderItemUUID] = useState([]);
    const [createData, setCreateData] = useState([]);
    const [productOptions, setProductOptions] = useState([]);
    const [inputProductNumber, setInputProductNumber] = useState();
    const { Option } = Select;

    const history = useHistory();

    async function onChangeDate(value, dateString) {
        setStartEndDate(dateString);
    }
    async function onChangeOrderNumber(e) {
        setOrderNumber(e.target.value);
    }
    async function onChangeCustomerName(value) {
        setCustomerName(value);
    }
    function changeProductNumber(value) {
        setInputProductNumber(value);
    }
    //run search button
    async function searchByRule() {
        await OrderQueryByDate(startEndDate, orderNumber, customerName).then(data => {
            //add key attribute
            for (let i = 0; i < data.length; ++i) {
                data[i].key = i;
            }
            //product name to compare uuid
            setOrderDataByDate(data);
            if (inputProductNumber) {
                if (orderItemUUID.length !== 0) {
                    let tempData = [];
                    setOrderDataByDate([]);
                    _.forEach(data, function (item) {
                        _.forEach(orderItemUUID, function (uuid) {
                            if (item.OrderItemUUID === uuid) {
                                tempData.push(item)
                            }
                        });
                    });
                    setOrderDataByDate(tempData)
                } else {
                    setOrderDataByDate([]);
                }
            }
        })
            .catch(err => console.log(err));
        await history.push("/orders/");
        await history.push("/orders/all");
    }

    async function queryCustomersList() {
        await getContacts("", "").then(data => {
            //filter customerMode is  true data
            let trueModeData = _.filter(data, ['CustomerMode', "true"]);
            setCustomersList(trueModeData);
        })
            .catch(err => console.log(err))
    }

    async function onSearch(event) {

        await GetProductsLike(event).then(data => {
            setProductOptions(data.data);
        })
            .catch(err => console.log(err))
    }

    async function onSelect(event) {
        setOrderItemUUID([]);
        await searchProductNumber(event).then(data => {
            //input only orderItemUUID
            var temp = [];
            data.map((item) => {
                temp.push(item.OrderItemUUID);
                return temp;
                // setOrderItemUUID(orderItemUUID => [...orderItemUUID, item.OrderItemUUID]);

            })
            setOrderItemUUID(_.uniq(temp));
        })
            .catch(err => console.log(err));
    }

    useEffect(() => {
        searchByRule();
        queryCustomersList();
        //eslint-disable-next-line
    }, [])

    function callback(key) {
        switch (key) {
            case "1":
                history.push("/orders/all");
                break;
            case "2":
                history.push("/orders/notSureOrders");
                break;
            case "3":
                history.push("/orders/findOrders");
                break;
            case "4":
                history.push("/orders/sureOrders");
                break;
            case "5":
                history.push("/orders/processed");
                break;
            case "6":
                history.push("/orders/producting");
                break;
            case "7":
                history.push("/orders/handed");
                break;
            case "8":
                history.push("/orders/closed");
                break;
            default:
                break;
        }
    }

    return (
        <>
            <Row>
                <Col span={24}>
                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={24}>
                            <OrderModal searchByRule={searchByRule} setCreateData={setCreateData} createData={createData}></OrderModal>
                        </Col>
                        <Col span={3} >
                            <h1 style={{ position: 'relative' }}>交付日期 : </h1>
                        </Col>
                        <Col span={5} >
                            <RangePicker showTime
                                onChange={onChangeDate}
                                style={{ position: 'relative', textAlign: 'center', right: '5vh' }}
                                // {moment(record.updateTime).format('YYYY-MM-DDTHH:mm:ss')}
                                defaultValue={[moment('2021/10/01', dateFormat), moment()]}
                                format={dateFormat}
                            />
                        </Col>
                        <Col span={2}>
                            <h1>訂單編號 : </h1>
                        </Col>
                        <Col span={5} >
                            <Input allowClear onChange={onChangeOrderNumber} placeholder="訂單編號" size="middle" style={{ width: '100%', textAlign: 'center', right: '5vh' }} />
                        </Col>
                        <Col span={2}>
                            <h1>客戶名稱 : </h1>
                        </Col>
                        <Col span={5} >
                            <Select
                                allowClear
                                showSearch
                                placeholder="Select a Customer Name"
                                onChange={onChangeCustomerName}
                                style={{ width: '100%', right: '5vh' }}
                            >
                                {
                                    customersList.map((item) => {
                                        return (
                                            <Option key={item.UUID} value={item.CustomerName}>{item.CustomerName}</Option>
                                        )
                                    })
                                }
                            </Select>
                        </Col>

                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col span={2}>
                            <h1>物料編號 : </h1>
                        </Col>
                        <Col span={5} >
                            <AutoComplete
                                onChange={changeProductNumber}
                                allowClear
                                style={{ position: 'relative', left: '28px', width: 200 }}
                                onSelect={onSelect}
                                onSearch={onSearch}
                                placeholder="input here"
                            >
                                {
                                    productOptions.map((products) => {
                                        return (
                                            <Option key={products.uuid} value={products.number}>{products.number}</Option>
                                        )
                                    })
                                }
                            </AutoComplete>
                        </Col>
                        <Col span={2} >
                            <Button size="middle" type="primary" onClick={searchByRule}>搜尋</Button>
                        </Col>

                    </Row>
                </Col>
            </Row>
            <Tabs defaultActiveKey="1" onChange={callback} >
                <TabPane tab="全部訂單" key="1">
                    <Switch>
                        <Route path="/orders/all">
                            <OrdersTable searchByRule={searchByRule} orderDataByDate={orderDataByDate} orderMode={""} ></OrdersTable>
                        </Route>
                    </Switch>
                </TabPane>
                <TabPane tab="未確認訂單" key="2">
                    <Switch>
                        <Route path="/orders/notSureOrders">
                            <OrdersTable searchByRule={searchByRule} orderDataByDate={orderDataByDate} orderMode={"未確認訂單"}></OrdersTable>
                        </Route>
                    </Switch>
                </TabPane>
                <TabPane tab="尋單" key="3">
                    <Switch>
                        <Route path="/orders/findOrders">
                            <OrdersTable searchByRule={searchByRule} orderDataByDate={orderDataByDate} orderMode={"尋單"}></OrdersTable>
                        </Route>
                    </Switch>
                </TabPane>
                <TabPane tab="確認訂單" key="4">
                    <Switch>
                        <Route path="/orders/sureOrders">
                            <OrdersTable searchByRule={searchByRule} orderDataByDate={orderDataByDate} orderMode={"確認訂單"}></OrdersTable>
                        </Route>
                    </Switch>
                </TabPane>
                <TabPane tab="已排程" key="5">
                    <Switch>
                        <Route path="/orders/processed">
                            <OrdersTable searchByRule={searchByRule} orderDataByDate={orderDataByDate} orderMode={"已排程"}></OrdersTable>
                        </Route>
                    </Switch>
                </TabPane>
                <TabPane tab="生產中" key="6">
                    <Switch>
                        <Route path="/orders/producting">
                            <OrdersTable searchByRule={searchByRule} orderDataByDate={orderDataByDate} orderMode={"生產中"}></OrdersTable>
                        </Route>
                    </Switch>
                </TabPane>
                <TabPane tab="已交付" key="7">
                    <Switch>
                        <Route path="/orders/handed">
                            <OrdersTable searchByRule={searchByRule} orderDataByDate={orderDataByDate} orderMode={"已交付"}></OrdersTable>
                        </Route>
                    </Switch>
                </TabPane>
                <TabPane tab="已關閉" key="8">
                    <Switch>
                        <Route path="/orders/closed">
                            <OrdersTable searchByRule={searchByRule} orderDataByDate={orderDataByDate} orderMode={"已關閉"}></OrdersTable>
                        </Route>
                    </Switch>
                </TabPane>
            </Tabs>
        </>
    )
}
export default Order;
