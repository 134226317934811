import axiosInstance from "../api/axios";

export const queryCustomerNumber = (customerNumber) =>
{
    const promise = axiosInstance.get(`/customer/queryCustomerNumber?customerNumber=${customerNumber}`);
    const dataPromise = promise.then((response) => response.data)
    return dataPromise
};

export const getContacts = (customerName, customerNumber) =>
{
    //customer
    const promise = axiosInstance.get(`/customer/query?customerName=${customerName}&customerNumber=${customerNumber}`);
    const dataPromise = promise.then((response) => response.data)
    return dataPromise
};
export default getContacts;
