import axios from 'axios';
import queryString from 'query-string';
import Cookies from 'js-cookie';
//
// IMPORT ZONE
//

export const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export let axiosInstance;

export const initAxiosInstance = (idToken) => {
    axiosInstance = axios.create();

    axiosInstance.interceptors.request.use((request) => {
        request.headers['Authorization'] = `Bearer ${Cookies.get('token')}`;
        return request;
    }, (error) => {
        return Promise.reject(error);
    });

    axiosInstance.interceptors.response.use((request) => {
        return request;
    }, (error) => {
        if (error.toString() === 'Error: Network Error') {
            return Promise.reject(new Error('Network Error'));
        }

        if (error.response.status.toString() === '401') {
            // redirect 到 login 頁面！
            const params = queryString.parse(window.location.search);
            const redirectUrl = window.location.origin;
            const tenantId = sessionStorage.getItem('tenant_id') || params.tenantId || '';
            const loginUrl = process.env.REACT_APP_LOGIN_URL + '/login?tenantId=' + tenantId + '&redirectUri=' + redirectUrl;
            //
            if (process.env.REACT_APP_ENV !== 'dev') {
                window.location.replace(loginUrl);
            }
        }
        return Promise.reject(error);
    });
};

export const getAppPermission = async (token) => {
    return axios({
        method: 'get',
        url: process.env.REACT_APP_USER_URL + 'permission/app?name=sd',
        headers: { Authorization: `Bearer ${token}` },
    }).then((res) => {
        return res.data;
    });
};
