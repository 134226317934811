import axiosInstance from "../api/axios";
import { v4 as uuidv4 } from 'uuid';

export const queryOrderNumber = (orderNumber) => {

    const promise = axiosInstance.get(`/order/queryOrderNumber?orderNumber=${orderNumber}`);
    const dataPromise = promise.then((response) => response.data)
    return dataPromise
};

export const upsertOrder = (params) => {
    if (params.UUID === "") {
        params.UUID = uuidv4();
    }
    axiosInstance.patch('/order/upsert', params, {
        params: { "UUID": params.UUID }
    })
        .then((res) => {
            console.log("upsert order", res.data)
        })
        .catch((err) => console.log("err", err));
};

export const upsertItem = (params) => {
    params.forEach((items) => {
        axiosInstance.patch('/item/upsert', items, {
            params: { "OrderItemUUID": items.OrderItemUUID }
        })
            .then((res) => {
                console.log("upsert items", res.data)
            })
            .catch((err) => console.log("err", err));
    }
    )
};
export default upsertOrder;
