import {
    Radio,
    Row,
    Col,
    Card,
    Table,
    Divider,
} from 'antd';
import _ from "lodash";
import React, { useState, useEffect } from 'react';
import { OrderQueryByDate } from "../../contacts/OrderQuery";
import { QueryItemByOrderItemUUID } from '../../contacts/QueryItemByOrderItemUUID';
import moment from "moment";
import CustomersPie from './CustomersPie';
import MoneyColumn from './MoneyColumn';
const Home = () => {
    const [customersPieData, setCustomersPieData] = useState([]);
    const [moneyColumnData, setMoneyColumnData] = useState([]);
    const dateFormat = 'YYYY-MM-DD HH:mm:ss';
    const weekStartEndDate = [moment(moment().week(moment().week()).startOf('week').valueOf()).format(dateFormat), moment(moment().week(moment().week()).endOf('week').valueOf()).format(dateFormat)];
    const monthStartEndDate = [moment(moment().month(moment().month()).startOf('month').valueOf()).format(dateFormat), moment(moment().month(moment().month()).endOf('month').valueOf()).format(dateFormat)];
    const seasonStartEndDate = [moment(moment().quarter(moment().quarter()).startOf('quarter').valueOf()).format(dateFormat), moment(moment().quarter(moment().quarter()).endOf('quarter').valueOf()).format(dateFormat)];
    const [producting, setProducting] = useState(0);
    const [processed, setProcessed] = useState(0);
    const [notSureOrders, setNotSureOrders] = useState(0);
    const [sureOrders, setSureOrders] = useState(0);
    const [productData, setProductData] = useState([]);

    const columns = [
        {
            title: '物料編號',
            dataIndex: 'ProductNumber',
            key: 'ProductNumber'
        },
        {
            title: '物料名稱',
            dataIndex: 'ProductName',
            key: 'ProductName'
        },
        {
            title: '訂單編號',
            sort: true,
            dataIndex: 'OrderNumber',
            key: 'OrderNumber'
        },
        {
            title: '客戶編號',
            dataIndex: 'CustomerNumber',
            key: 'CustomerNumber'
        },
        {
            title: '交期',
            key: 'DeliveryDate',
            sorter: (a, b) => {
                let aTimeString = a.DeliveryDate;
                let bTimeString = b.DeliveryDate;
                let aTime = new Date(aTimeString).getTime();
                let bTime = new Date(bTimeString).getTime();
                return aTime - bTime;
            },
            render: (record) => (
                <>
                    {moment(record.DeliveryDate).format('YYYY-MM-DD HH:mm:ss')}
                </>
            )
        },
        {
            title: '創建日期',
            key: 'createTime',
            sorter: (a, b) => {
                let aTimeString = a.createTime;
                let bTimeString = b.createTime;
                let aTime = new Date(aTimeString).getTime();
                let bTime = new Date(bTimeString).getTime();
                return aTime - bTime;
            },
            render: (record) => (
                <>
                    {moment(record.createTime).format('YYYY-MM-DD HH:mm:ss')}
                </>
            )
        },
        {
            title: '更新日期',
            key: 'updateTime',
            sorter: (a, b) => {
                let aTimeString = a.updateTime;
                let bTimeString = b.updateTime;
                let aTime = new Date(aTimeString).getTime();
                let bTime = new Date(bTimeString).getTime();
                return aTime - bTime;
            },
            render: (record) => (
                <>
                    {moment(record.updateTime).format('YYYY-MM-DD HH:mm:ss')}
                </>
            )
        },
    ];


    async function onChange(e) {
        setProductData([]);

        if (e.target.value === 'week') {
            await searchByRule(weekStartEndDate);
        } else if (e.target.value === 'month') {
            await searchByRule(monthStartEndDate);
        } else if (e.target.value === 'season') {
            await searchByRule(seasonStartEndDate);
        }
    }

    async function searchByRule(date) {
        let countProducting = 0;
        let countProcessed = 0;
        let countNotSureOrders = 0;
        let countSureOrders = 0;
        console.log("startEndDate1", date)
        await OrderQueryByDate(date, '', '').then(data => {
            console.log("data search", data);
            getItemsProductData(data);

            //分類數量
            _.forEach(data, function (orderData) {
                var obj = {};
                obj.type = orderData.CustomerName;
                obj.value = orderData.Money;
                console.log("obj ", obj)
                setCustomersPieData(customersPieData => [...customersPieData, obj]);

                switch (orderData.OrderMode) {
                    case '生產中':
                        countProducting++
                        break;
                    case '已排程':
                        countProcessed++
                        break;
                    case '未確認訂單':
                        countNotSureOrders++
                        break;
                    case '確認訂單':
                        countSureOrders++
                        break;
                    default:
                        break;
                }
                setProducting(countProducting);
                setProcessed(countProcessed);
                setNotSureOrders(countNotSureOrders);
                setSureOrders(countSureOrders);
            });
        })
            .catch(err => console.log(err));
    }
    async function getItemsProductData(items) {
        for (let j = 0; j < items.length; j++) {
            //item data
            await QueryItemByOrderItemUUID(items[j].OrderItemUUID).then(
                data => {
                    for (let i = 0; i < data.length; i++) {
                        data[i].OrderNumber = items[j].OrderNumber;
                        data[i].CustomerNumber = items[j].CustomerNumber;
                        data[i].type = items[j].OrderNumber;
                        data[i].sales = items[j].Money;
                        setProductData(productData => [...productData, data[i]]);
                        setMoneyColumnData(moneyColumnData => [...moneyColumnData,data[i]]);
                    }
                }
            ).catch(err => console.log(err));
        }

    }
    useEffect(() => {
        searchByRule(weekStartEndDate);
        // eslint-disable-next-line
    }, [])


    return (
        <div>
            <Row>
                <Col span={12} ></Col>
                <Col span={12} style={{ position: 'relative', right: '90px' }}>
                    <Radio.Group
                        centered
                        optionType="button"
                        buttonStyle="solid"
                        onChange={onChange}
                        defaultValue="week">
                        <Radio.Button value="week">本週</Radio.Button>
                        <Radio.Button value="month">本月</Radio.Button>
                        <Radio.Button value="season">本季</Radio.Button>
                        {/* <Radio.Button value="all">全部</Radio.Button> */}
                    </Radio.Group>
                </Col>
                {/* <Col xs={2} sm={4} md={6} lg={8} xl={10}> */}
                {/* </Col> */}
            </Row>
            <Row gutter={[16, 16]} style={{ position: 'relative', top: '5vh' }}>
                <Col span={12}>
                    <Card headStyle={{ backgroundColor: 'rgb(0, 168, 209)', border: 0 }} title={<span style={{ fontSize: '3rem', fontWeight: 'bold', textAlign: 'center' }}>生產中</span>} style={{ borderRadius: '5px', border: '3px solid rgb(0, 0, 0)', fontSize: '3rem', fontWeight: 'bold', textAlign: 'center' }}>
                        {producting}
                    </Card>
                </Col>
                <Col span={12}>
                    <Card headStyle={{ backgroundColor: 'rgb(151, 198, 25)', border: 0 }} title={<span style={{ fontSize: '3rem', fontWeight: 'bold', textAlign: 'center' }}>已排程</span>} style={{ borderRadius: '5px', border: '3px solid rgb(0, 0, 0)', fontSize: '3rem', fontWeight: 'bold', textAlign: 'center' }}>
                        {processed}
                    </Card>
                </Col>
                <Col span={12}>
                    <Card headStyle={{ backgroundColor: 'rgb(159, 153, 154)', border: 0 }} title={<span style={{ fontSize: '3rem', fontWeight: 'bold', textAlign: 'center' }}>未確認訂單</span>} style={{ borderRadius: '5px', border: '3px solid rgb(0, 0, 0)', fontSize: '3rem', fontWeight: 'bold', textAlign: 'center' }}>
                        {notSureOrders}
                    </Card>
                </Col>
                <Col span={12}>
                    <Card headStyle={{ backgroundColor: '#85a5ff', border: 0 }} title={<span style={{ fontSize: '3rem', fontWeight: 'bold', textAlign: 'center' }}>確認訂單</span>} style={{ borderRadius: '5px', border: '3px solid rgb(0, 0, 0)', fontSize: '3rem', fontWeight: 'bold', textAlign: 'center' }}>
                        {sureOrders}
                    </Card>
                </Col>
                <Divider plain style={{ fontWeight: '600', fontSize: '20px' }}>物料資訊</Divider>
            </Row>
            <Table
                rowKey={record => record.key}
                style={{ position: 'relative', top: '10vh' }}
                pagination={{ pageSize: 8 }}
                columns={columns}
                dataSource={productData}
            />
            <Row gutter={[16, 16]} style={{ position: 'relative', top: '13vh' }}>
                <Divider plain style={{ fontWeight: '600', fontSize: '20px' }}>客戶訂單金額</Divider>
                <Col span={12}>
                    <CustomersPie data={customersPieData}></CustomersPie>
                </Col>
                <Col span={12}>
                    <MoneyColumn data={moneyColumnData}></MoneyColumn>
                </Col>
            </Row>
        </div >

    )
}
export default Home;
