import axiosInstance from "../api/axios";
import { v4 as uuidv4 } from 'uuid';
export const upsertCustomers = (props) =>
{
    if(!props.UUID){

        props.UUID = uuidv4();
    }
    axiosInstance.patch('/customer/upsertCustomers', null,{params:props})
        .then((res) => {
            console.log("upsert axios",res.data)
        })
        .catch((err) => console.log("err",err));
}
export default upsertCustomers;
