import axiosInstance from "../api/axios";
// import Cookies from 'js-cookie';
export const OrderQuery = (params) => {
    //customer
    const promise = axiosInstance.get(`/order/query?orderMode=${params}`);
    const dataPromise = promise.then((response) => response.data)
    return dataPromise
}
//select by rangeDate
export const OrderQueryByDate = (startEndDate, orderNumber, customerName) => {
    //customer
    const promise = axiosInstance.get(`/order/queryByDate?from=${startEndDate[0]}&to=${startEndDate[1]}&orderNumber=${orderNumber}&customerName=${customerName}`);
    const dataPromise = promise.then((response) => response.data)
    return dataPromise
}
export default OrderQuery;
