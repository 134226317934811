import { Input, Button, Modal, Form, message, Switch } from 'antd';
import React, { useState } from 'react';
import moment from "moment";
import { useHistory } from 'react-router-dom';
import CustomerUpsert from '../../contacts/CustomerUpsert';
import { queryCustomerNumber } from '../../contacts/getContacts';

function CustomerModal({ record, callQueryCustomerAPI }) {
    const [visible, setVisible] = useState(false);
    const [title, setTitle] = useState();
    // const [checkBoxLabel, setCheckBoxLabel] = useState();
    // const [checkBox, setCheckBox] = useState();
    const [customerMode, setCustomerMode] = useState(false);
    const [form] = Form.useForm();
    const history = useHistory();
    //after push edit button
    function edit() {
        //set customer mode switch
        if (record.CustomerMode === "true") {
            setCustomerMode(true);
        }
        else {
            setCustomerMode(false);
        }

        setVisible(true);
        // setCheckBoxLabel(record.CustomerMode);
        // if (record.CustomerMode === "true")
        //     setCheckBox(true);
        // else
        //     setCheckBox(false);

        form.setFieldsValue(record);
        setTitle("編輯客戶資料")
    }
    function handleCancel() {
        setVisible(false);
        form.resetFields();
    }
    function createOrder() {
        setVisible(true);
        setTitle("新增客戶資料")
    }

    async function setChangeData() {
        const tempCustomerData = form.getFieldsValue(true);
        // tempCustomerData.CustomerMode = checkBoxLabel;
        tempCustomerData.CustomerMode = customerMode.toString();
        if (!record){
            var tempOrderNumberData = await queryCustomerNumber(tempCustomerData.CustomerNumber);
            if (tempOrderNumberData.length !== 0) {
                message.error('訂單編號已重複，請重新輸入!');
                return;
            }
        }


        await CustomerUpsert(tempCustomerData);
        message.success('儲存編輯成功!');
        setVisible(false);
        form.resetFields();
        //refresh data
        setTimeout(() => {
            callQueryCustomerAPI();//reload query customer data
        }, 1500);
        // await callQueryCustomerAPI();
        await history.push(`/customers`);
    }

    function onSwitchChange(checked) {
        setCustomerMode(checked);
    }
    // function onChangeCheckBox(e) {
    //     console.log("value", e.target.checked)
    //     // setCheckBoxLabel(e.target.checked.toString());
    //     // setCheckBox(e.target.checked);
    // }
    return (
        <div>
            {/* create or update customer data */}
            {
                record ?
                    <Button type="primary" onClick={edit}>編輯</Button>
                    :
                    <Button type="primary" style={{ position: 'relative' }} onClick={createOrder}>創建新客戶</Button>
            }

            <Modal
                title={title}
                visible={visible}
                onCancel={handleCancel}
                footer={
                    [
                        <Button key="cancel" onClick={handleCancel} style={{ position: 'relative', right: '53%' }}>
                            取消
                        </Button>,
                        // <Button key="submit" type="primary" htmlType="submit" onClick={setChangeData} style={{ position: 'relative', right: '30%' }}>
                        //     存檔
                        // </Button>
                    ]}
                    >
                <Form
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 10 }}
                    layout="horizontal"
                    onFinish={setChangeData}
                    form={form}
                    initialValues={{
                        'CustomerNumber': moment().format('YYYYMMDD-HHmmss'),
                    }}
                >
                    <Form.Item
                        name="CustomerNumber"
                        label="客戶編號"
                        rules={[{ required: true, message: '請輸入客戶編號!' }]}
                    >
                        <Input style={{ position: 'relative', left: '20px', width: '200px' }} />
                    </Form.Item>
                    <Form.Item
                        name="CustomerName"
                        label="客戶名稱"
                        rules={[{ required: true, message: '請輸入客戶名稱!' }]}
                    >
                        <Input style={{ position: 'relative', left: '20px', width: '200px' }} />
                    </Form.Item>
                    <Form.Item
                        label="客戶地址"
                        name="DestinationAddress"
                    >
                        <Input style={{ position: 'relative', left: '20px', width: '200px' }} />
                    </Form.Item>
                    <Form.Item
                        label="貨運地點"
                        name="SourceAddress"
                    >
                        <Input style={{ position: 'relative', left: '20px', width: '200px' }} />
                    </Form.Item>
                    <Form.Item
                        label="城市&emsp;&emsp;"
                        name="City"
                    >
                        <Input style={{ position: 'relative', left: '20px', width: '200px' }} />
                    </Form.Item>
                    <Form.Item
                        label="狀態&emsp;&emsp;"
                        name="CustomerMode"
                    >
                        <Switch style={{ position: 'relative', left: '20px'}} checkedChildren="啟用" unCheckedChildren="停用" defaultChecked={customerMode} onChange={onSwitchChange} />
                    </Form.Item>
                    <Button type="primary" htmlType="submit" style={{ position: 'relative', top: '67px', left: '270px' }}>存檔</Button>
                 </Form>
            </Modal>
        </div>

    )
}
export default CustomerModal;
