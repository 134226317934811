import React from 'react';
import { Column } from '@ant-design/charts';
const MoneyColumn = ({ data }) => {
    const config = {
        forceFit: true,
        data,
        padding: 'auto',
        xField: 'type',
        yField: 'sales',
        meta: {
            type: { alias: '類别' },
            sales: { alias: '金額' },
        },
        colorField: 'type',
        color: ['#55A6F3', '#55A6F3', '#55A6F3', '#CED4DE', '#55A6F3', '#55A6F3', '#55A6F3', '#55A6F3'],
        label: {
            visible: true,
            position: 'middle',
            adjustColor: true,
        },
    };

    return (
        <Column {...config}></Column>

    )
}
export default MoneyColumn;
